import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum } from '@/stores/dispatcher'
import { walletStore } from '@/stores/wallet-store'
import { map } from 'lodash'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export type FollowTab = 'follower' | 'following' | 'tag-following' | 'dao-following'
export class FollowingViewModel {
  @observable userProfile?: ProfileModel
  @observable tabActive: FollowTab = 'follower'

  //Followers
  @observable followerList: any[] = []
  @observable followerLoading = false
  @observable followerSearchText = ''
  @observable followerListSearch: any = []

  //Following
  @observable followingList: any[] = []
  @observable followingLoading = false
  @observable followingSearchText = ''
  @observable followingListSearch: any = []

  //Tag follow
  @observable tagFollowingList: any[] = []
  @observable tagLoading = false
  @observable tagSearchText = ''
  @observable tagFollowingListSearch: any[] = []

  //Dao follow
  @observable daoFollowingList: any[] = []
  @observable daoLoading = false
  @observable daoSearchText = ''
  @observable daoFollowingListSearch: any[] = []

  @observable totalFollowing = 0 as any
  @observable totalFollower = 0 as any
  @observable totalTag = 0
  private _unsubcrible = new Subject()

  constructor() {
    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
  }

  @asyncAction *syncFollowProfile(profile: FollowingProfile) {
    let userIndex
    if (profile.type === FollowTypeEnum.user) {
      userIndex = this.followerList.findIndex((item) => item?.follower?._id === profile.to)
      if (userIndex !== -1) {
        this.followerList[userIndex] = {
          ...this.followerList[userIndex],
          isFollowing: profile.followState,
          loading: false,
        }
      }

      userIndex = this.followingList.findIndex((item) => item?.follow?._id === profile.to)
      if (userIndex !== -1) {
        {
          this.followingList[userIndex] = {
            ...this.followingList[userIndex],
            isFollowing: profile.followState,
            loading: false,
          }
        }
      }

      if (profile.to === this.userProfile?._id) {
        if (map(this.followerList, 'follower.id').includes(profile.from)) {
          if (!profile.followState) {
            this.followerList = this.followerList.filter((item) => item.follower?._id !== profile.from)
          }
        } else {
          if (profile.followState)
            this.followerList.push({
              follow: this.userProfile,
              follower: walletStore.userProfile,
              isFolowwing: true,
              loading: false,
            })
        }
        this.totalFollower = this.followerList.length
      }

      if (profile.from === this.userProfile?._id) {
        if (map(this.followingList, 'follow.id').includes(profile.to)) {
          this.totalFollowing = profile.followState ? this.followingList.length : this.followingList.length - 1
        } else {
          if (profile.followState) {
            const newFollowingUser = yield apiService.profiles.findOne(profile.to)
            this.followingList.push({
              follow: newFollowingUser,
              follower: this.userProfile,
              isFollowing: true,
              loading: false,
            })
          }

          this.totalFollowing = this.followingList.length
        }
      }
    } else if (profile.type === FollowTypeEnum.tag && this.isProfileOwner) {
      const index = this.tagFollowingList.findIndex((item) => item.tag._id === profile.to)
      if (index !== -1) {
        this.tagFollowingList[index] = {
          ...this.tagFollowingList[index],
          isFollowing: profile.followState,
          loading: false,
        }
        this.totalTag = this.tagFollowingList.filter((item) => item.isFollowing).length
      }
    }
    this.searchFollower()
    this.searchFollowing()
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @asyncAction *init() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        if (this.userProfile?._id) {
          this.getFollowers(this.userProfile?._id)
          this.getFollowings(this.userProfile._id)
          this.getFollowingTags(this.userProfile._id)
          this.getFollowingDaos(this.userProfile._id)
        }
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound changeActiveTab(tab: FollowTab) {
    this.tabActive = tab
  }

  @asyncAction *getFollowers(profileId: string) {
    try {
      this.followerLoading = true
      const followers = yield apiService.userFollows.getUserFollowState({ follow: profileId })
      this.followerList = followers
      this.followerListSearch = followers
      this.totalFollower = this.followerList.length
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.followerLoading = false
    }
  }

  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.follower.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.follower.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action.bound searchFollower() {
    if (this.followerSearchText) {
      const searchTags = this.followerList.filter((item) => item.follower.username?.includes(this.followerSearchText))
      this.followerListSearch = searchTags
    } else {
      this.followerListSearch = this.followerList
    }
  }

  @asyncAction *getFollowingDaos(profileId: string) {
    try {
      this.daoLoading = true
      const daos = yield apiService.daos.getDaoFollowState({ profile: profileId })
      this.daoFollowingList = daos
      this.daoFollowingListSearch = daos
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.daoLoading = false
    }
  }

  @asyncAction *followDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.joinDao({ daoId: item.dao.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.leaveDao({ daoId: item.dao.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action searchDao() {
    const searchDaos = this.daoFollowingList.filter((item) => item.dao.name?.includes(this.daoSearchText))
    this.daoFollowingListSearch = searchDaos
  }

  @action handleDaoClick(tag) {
    appProvider.router.push(`/forum/post?tags=${tag.id}`)
  }

  @asyncAction *getFollowingTags(profileId: string) {
    try {
      this.tagLoading = true
      const tags = yield apiService.tagFollows.getTagFollowState({ follower: profileId })
      this.tagFollowingList = tags
      this.tagFollowingListSearch = tags
      this.totalTag = tags.length
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.tagLoading = false
    }
  }

  @asyncAction *followTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.followTag({ tag: item.tag._id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: item.tag._id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action searchTag() {
    const searchTags = this.tagFollowingList.filter((item) => item.tag.content?.includes(this.tagSearchText))
    this.tagFollowingListSearch = searchTags
  }

  @action handleTagClick(tag) {
    appProvider.router.push(`/forum/post?tags=${tag.id}`)
  }

  @asyncAction *getFollowings(profileId: string) {
    try {
      this.followingLoading = true
      const followings = yield apiService.userFollows.getUserFollowState({ follower: profileId })
      this.followingList = followings
      this.followingListSearch = followings
      this.totalFollowing = this.followingList.length
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.followingLoading = false
    }
  }

  @asyncAction *followingFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.follow.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *followingUnFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.follow.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action searchFollowing() {
    if (this.followingSearchText) {
      const searchFollowing = this.followingList.filter((item) =>
        item.follow.username?.includes(this.followingSearchText)
      )
      this.followingListSearch = searchFollowing
    } else {
      this.followingListSearch = this.followingList
    }
  }

  @computed get listLeft() {
    switch (this.tabActive) {
      case 'follower':
        return this.followerListSearch.filter((item, index) => index % 2 === 0)
      case 'following':
        return this.followingListSearch.filter((item, index) => index % 2 === 0)
      case 'dao-following':
        return this.daoFollowingListSearch.filter((item, index) => index % 2 === 0)
      case 'tag-following':
        return this.tagFollowingListSearch.filter((item, index) => index % 2 === 0)
      default:
        return []
    }
  }

  @computed get listRight() {
    switch (this.tabActive) {
      case 'follower':
        return this.followerListSearch.filter((item, index) => index % 2 === 1)
      case 'following':
        return this.followingListSearch.filter((item, index) => index % 2 === 1)
      case 'dao-following':
        return this.daoFollowingListSearch.filter((item, index) => index % 2 === 1)
      case 'tag-following':
        return this.tagFollowingListSearch.filter((item, index) => index % 2 === 1)
      default:
        return []
    }
  }
  @computed get isProfileOwner() {
    const id = this.userProfile?._id
    return id && id == walletStore.userProfile?._id
  }
}
