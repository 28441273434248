



































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { FollowingViewModel } from '../viewmodels/following-viewmodel'

@Observer
@Component({
  components: {
    'follower-table': () => import('./components/following/follower-table.vue'),
    'following-table': () => import('./components/following/following-table.vue'),
    'tag-follow-table': () => import('./components/following/tag-follow-table.vue'),
    'dao-follow-table': () => import('./components/following/dao-follow-table.vue'),
  },
})
export default class Following extends Vue {
  @Provide() vm = new FollowingViewModel()

  @Watch('$route.params.userId', { immediate: true }) async userChange() {
    await this.vm.init()
  }
  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#following') {
        this.vm.changeActiveTab('following')
      }
      if (hash === '#follower') {
        this.vm.changeActiveTab('follower')
      }
      if (hash === '#tag-following') {
        this.vm.changeActiveTab('tag-following')
      }
      if (hash === '#dao') {
        this.vm.changeActiveTab('dao-following')
      }
      setTimeout(() => {
        document.getElementById(`follow-list`)?.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
